import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  TextClickToCopy,
  Description,
  DescriptiveList,
  Term,
  Definition,
  Heading,
  Modal,
} from '@studio/legacy-components';
import { keyShape } from 'entities/keys';
import { selectAccountUserFullName } from 'reducers/account/users';

import { PERMISSIONS_OPTIONS } from './constants';

const DescriptionWrapper = styled.div`
  margin-top: 12px;
`;

const DetailWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
`;

const DateWrapper = styled.div`
  margin-bottom: 8px;
  color: var(--banner-text);
`;

const NameWrapper = styled.span`
  word-break: break-word;
`;

const AccessDescription = styled.span`
  font-size: 12px;
  padding-left: 4px;
`;

const ButtonsWrapper = styled(ButtonGroup)`
  margin-top: 24px;
`;

const DATE_FORMAT = 'MMM D, YYYY h:mm A';

export const KeyDetail = ({ visible, onClose, apiKey, createdByUserName }) => {
  const { name, key, inserted_at, role_id, state, last_seen_at } = apiKey;
  const isActive = state === 'active';
  const accessPermission = PERMISSIONS_OPTIONS.find(
    permission => permission.value === role_id
  );

  return (
    <Modal onClose={onClose} size="m" visible={visible}>
      <Heading>API Key Details</Heading>
      <DescriptionWrapper>
        <Description>
          Use this key for existing Appcues integrations.
        </Description>
      </DescriptionWrapper>

      <DetailWrapper>
        <DescriptiveList>
          <Term>Name:</Term>
          <Definition>
            <NameWrapper>{name}</NameWrapper>
          </Definition>
          <Term>Key:</Term>
          <Definition>
            <div className="fs-mask">
              <TextClickToCopy text={key} />
            </div>
          </Definition>
          <Term>Access Permissions:</Term>
          <Definition>
            {accessPermission.label}{' '}
            <AccessDescription>
              ({accessPermission.description})
            </AccessDescription>
          </Definition>
          <Term>Status:</Term>
          <Definition>{isActive ? 'Enabled' : 'Disabled'}</Definition>
        </DescriptiveList>
      </DetailWrapper>

      <DateWrapper>
        Created {createdByUserName ? `by ${createdByUserName}` : ''} on{' '}
        {inserted_at ? moment(inserted_at).format(DATE_FORMAT) : '-'}
      </DateWrapper>
      {last_seen_at && (
        <DateWrapper>
          Last used on {moment(last_seen_at).format(DATE_FORMAT)}
        </DateWrapper>
      )}
      <ButtonsWrapper right>
        <Button kind="primary" onClick={onClose}>
          Done
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    apiKey: { created_by },
  } = ownProps;

  return {
    createdByUserName: selectAccountUserFullName(state, created_by),
  };
};

KeyDetail.propTypes = {
  createdByUserName: PropTypes.string,
  apiKey: keyShape,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(KeyDetail);
