import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Conditions, CLAUSE_TYPES } from '@studio/conditions';

import {
  BANNERS_CONDITIONS,
  CHECKLIST_TARGETING,
  selectFeature,
} from 'next/entities/features';
import {
  selectBannerTargetingOptions,
  selectChecklistTargetingOptions,
  selectEmailTargetingOptions,
  selectEventTargetingOptions,
  selectFlowTargetingOptions,
  selectGroupAttributeOptions,
  selectMobileFlowTargetingOptions,
  selectPinTargetingOptions,
  selectProfileAttributeOptions,
  selectPushTargetingOptions,
  selectSegmentTargetingOptions,
} from 'next/lib/selectors-options';
import { SelectOptionsShape } from 'next/lib/shapes';

export const ConditionsWrapper = ({
  bannerOptions = [],
  checklistOptions = [],
  emailOptions = [],
  eventOptions = [],
  flowOptions = [],
  groupOptions = [],
  hasBannerTargeting,
  hasChecklistTargeting,
  mobileFlowOptions = [],
  pinOptions = [],
  propertyOptions = [],
  pushOptions = [],
  segmentOptions = [],
  validClauseTypes = Object.keys(CLAUSE_TYPES),
  ...unhandledProps
}) => {
  return (
    <Conditions
      bannerOptions={bannerOptions}
      checklistOptions={checklistOptions}
      emailOptions={emailOptions}
      eventOptions={eventOptions}
      flowOptions={flowOptions}
      groupOptions={groupOptions}
      hasBannerTargeting={hasBannerTargeting}
      hasChecklistTargeting={hasChecklistTargeting}
      mobileFlowOptions={mobileFlowOptions}
      pinOptions={pinOptions}
      propertyOptions={propertyOptions}
      pushOptions={pushOptions}
      segmentOptions={segmentOptions}
      validClauseTypes={validClauseTypes}
      {...unhandledProps}
    />
  );
};

ConditionsWrapper.propTypes = {
  bannerOptions: SelectOptionsShape,
  checklistOptions: SelectOptionsShape,
  emailOptions: SelectOptionsShape,
  eventOptions: SelectOptionsShape,
  flowOptions: SelectOptionsShape,
  groupOptions: SelectOptionsShape,
  hasBannerTargeting: PropTypes.bool,
  hasChecklistTargeting: PropTypes.bool,
  mobileFlowOptions: SelectOptionsShape,
  pinOptions: SelectOptionsShape,
  propertyOptions: SelectOptionsShape,
  pushOptions: SelectOptionsShape,
  segmentOptions: SelectOptionsShape,
  validClauseTypes: PropTypes.arrayOf(
    PropTypes.oneOf(Object.keys(CLAUSE_TYPES))
  ),
};

const mapStateToProps = state => ({
  bannerOptions: selectBannerTargetingOptions(state),
  checklistOptions: selectChecklistTargetingOptions(state),
  emailOptions: selectEmailTargetingOptions(state),
  eventOptions: selectEventTargetingOptions(state),
  flowOptions: selectFlowTargetingOptions(state),
  groupOptions: selectGroupAttributeOptions(state),
  hasBannerTargeting: selectFeature(state, BANNERS_CONDITIONS),
  hasChecklistTargeting: selectFeature(state, CHECKLIST_TARGETING),
  mobileFlowOptions: selectMobileFlowTargetingOptions(state),
  pinOptions: selectPinTargetingOptions(state),
  propertyOptions: selectProfileAttributeOptions(state),
  pushOptions: selectPushTargetingOptions(state),
  segmentOptions: selectSegmentTargetingOptions(state),
});

export default styled(connect(mapStateToProps)(ConditionsWrapper))``;
