import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PageBody, PageActions } from '@studio/legacy-components';
import { UserIdUploadModal } from '@studio/settings-panels';
import { ButtonLink } from 'studio-classic/src/components/Audience/Audiences/styled';
import useTitle from 'next/hooks/use-title';
import { selectAccountFeature } from 'reducers/account/features';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import IndexPage from 'components/Common/IndexPage';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';
import SegmentList from 'components/Audience/SegmentList';
import { uploadSegmentUserIds } from 'actions/account/segments';
import { asPromised } from 'utils/as-promised';

export const SegmentListPage = ({
  canCreateSegments,
  hasUserIdUpload,
  onUploadSegmentUserIds,
}) => {
  useTitle('Segments | Appcues');

  return (
    <IndexPage
      title="Segments"
      subHeaderActions={
        canCreateSegments ? (
          <PageActions>
            <ButtonLink to="/segments/new/edit" kind="primary">
              Create a Segment
            </ButtonLink>
            {hasUserIdUpload && (
              <UserIdUploadModal
                onUploadSegmentUserIds={onUploadSegmentUserIds}
              />
            )}
          </PageActions>
        ) : null
      }
    >
      <PageBody>
        <SegmentList />
      </PageBody>
    </IndexPage>
  );
};

SegmentListPage.propTypes = {
  canCreateSegments: PropTypes.bool,
  hasUserIdUpload: PropTypes.bool,
  onUploadSegmentUserIds: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    canCreateSegments:
      selectUserRole(state, selectUserId(state)) !== ACCOUNT_PERMISSIONS.EDITOR,
    hasUserIdUpload: selectAccountFeature(state, 'SEGMENT_USER_ID_UPLOAD'),
  };
};

const mapDispatchToProps = dispatch => ({
  onUploadSegmentUserIds: body =>
    asPromised(dispatch, uploadSegmentUserIds(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SegmentListPage);
