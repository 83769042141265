import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import { CPage } from '@appcues/component-library';
import {
  INTEGRATION_LISTING_PAGE_REDESIGN,
  MOBILE_FLOWS,
  NEXT_SUBSCRIPTION,
  OPM_NEW_NAV,
  S5_PROPERTIES,
} from 'next/entities/features';

import { selectAccountFeature } from 'reducers/account/features';

import IndexPage from 'components/Common/IndexPage';
import Installation from 'components/Installation';
import NextInstallation from 'components/Installation/NextInstallation';
import Subscription from 'components/Subscription';
import AccountPage from 'components/AccountPage';
import Billing from 'components/SubscriptionBilling';
import IntegrationsPage from 'components/IntegrationsPage';
import Localization from 'components/Settings/Localization';
import ApiKeys from 'components/Settings/keys/ApiKeys';
import { FLOW_LOCALIZATION } from 'constants/features';
import { Events } from './Events';
import { Properties } from './Properties';

export function Settings({
  hasFlowLocalization,
  hasMobileFlows,
  hasIntegrationPageRedesign,
  hasNextSubscription,
  hasOPMNewNav,
  hasS5Properties,
}) {
  return (
    <IndexPage title="Settings">
      <CPage.Container>
        <Switch>
          <Route path="/settings/account" component={AccountPage} />
          <Route
            path="/settings/installation"
            component={hasMobileFlows ? NextInstallation : Installation}
          />
          {hasOPMNewNav && <Redirect path="/settings/themes" to="/themes" />}
          {hasIntegrationPageRedesign ? (
            <Redirect
              path="/settings/integrations"
              to="/integrations/connections"
            />
          ) : (
            <Route path="/settings/integrations" component={IntegrationsPage} />
          )}
          <Route path="/settings/events" component={Events} />
          {hasS5Properties ? (
            <Redirect
              from="/settings/properties"
              to="/settings/properties-v2/users"
            />
          ) : (
            <Route path="/settings/properties" component={Properties} />
          )}

          {hasNextSubscription ? (
            <Redirect
              from="/settings/subscription"
              to="/settings/subscription-v2"
            />
          ) : (
            <Route
              path="/settings/subscription"
              component={Subscription}
              exact
            />
          )}
          <Route path="/settings/billing" component={Billing} exact />

          {hasFlowLocalization && (
            <Route path="/settings/localization" component={Localization} />
          )}
          <Route path="/settings/keys" component={ApiKeys} />
          <Redirect from="/settings/install" to="/settings/installation" />
          <Redirect exact from="/settings" to="/settings/account" />
        </Switch>
      </CPage.Container>
    </IndexPage>
  );
}

Settings.propTypes = {
  hasFlowLocalization: PropTypes.bool,
  hasMobileFlows: PropTypes.bool,
  hasIntegrationPageRedesign: PropTypes.bool,
  hasNextSubscription: PropTypes.bool,
  hasOPMNewNav: PropTypes.bool,
  hasS5Properties: PropTypes.bool,
};

const mapStateToProps = state => ({
  hasFlowLocalization: selectAccountFeature(state, FLOW_LOCALIZATION),
  hasMobileFlows: selectAccountFeature(state, MOBILE_FLOWS),
  hasNextSubscription: selectAccountFeature(state, NEXT_SUBSCRIPTION),
  hasIntegrationPageRedesign: selectAccountFeature(
    state,
    INTEGRATION_LISTING_PAGE_REDESIGN
  ),
  hasOPMNewNav: selectAccountFeature(state, OPM_NEW_NAV),
  hasS5Properties: selectAccountFeature(state, S5_PROPERTIES),
});

export default connect(mapStateToProps)(Settings);
