import { createSelector } from 'reselect';

import { selectEventNameLabelsByName } from 'next/entities/event-name-labels';
import { selectProfileAttributeLabels } from 'next/entities/profile-attribute-labels';
import { selectTags } from 'next/entities/tags';
import { selectThemes } from 'next/entities/themes';
import { FLOW_TEMPLATE } from 'next/entities/templates';
import { selectGoals } from 'next/entities/goals';
import { selectApps, WEB_APP, MOBILE_PLATFORM_ICONS } from 'next/entities/apps';
import { selectTargetingOptions } from 'next/entities/targeting';
import { getPropertyDisplayName } from './profile-attributes';

export const selectChecklistTargetingOptions = createSelector(
  selectTargetingOptions,
  targeting =>
    (targeting?.checklists ?? []).map(({ id, internalName }) => ({
      label: internalName || id,
      value: id,
    }))
);

/**
 * Creates dropdown options from `selectPins` results
 *
 * @param {State} state - Redux state
 * @return {Array.<{ label: string, value: string, type: string }>}
 */
export const selectPinTargetingOptions = createSelector(
  selectTargetingOptions,
  targeting =>
    (targeting?.pins ?? []).flatMap(({ name, steps }) =>
      steps
        .sort((a, b) => a.index - b.index)
        .map(({ name: pinStepName, id, type }) => {
          return {
            label: `${name} (${pinStepName})`,
            value: id,
            type,
          };
        })
    )
);

/**
 * Select banner experiences for the banner targeting dropdown.
 *
 * @param {State} state - Redux state
 * @return {Array.<{ label: string, value: string }>}
 */
export const selectBannerTargetingOptions = createSelector(
  selectTargetingOptions,
  targeting =>
    (targeting?.banners ?? [])
      .map(({ name, steps, id }) => ({
        label: name,
        // @todo why is this banner_id instead of banner_step_id?
        value: id,
        stepId: steps[0].id, // Since banner only have one step, we pass the step id as the value
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
);

/**
 * Select emails for the email targeting dropdown.
 *
 * @param {State} state - Redux state
 * @return {Array.<{ label: string, value: string }>}
 */
export const selectEmailTargetingOptions = createSelector(
  selectTargetingOptions,
  targeting =>
    (targeting?.emails ?? [])
      .map(({ name, id, subject }) => ({
        label: `${name}`,
        value: id,
        subject,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
);

/**
 * Select pushes for the push targeting dropdown.
 *
 * @param {State} state - Redux state
 * @return {Array.<{ label: string, value: string }>}
 */
export const selectPushTargetingOptions = createSelector(
  selectTargetingOptions,
  targeting =>
    (targeting?.pushNotifications ?? [])
      .map(({ name, id, content }) => ({
        label: `${name}`,
        value: id,
        title: content?.title || '',
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
);

export const selectEventTargetingOptions = createSelector(
  selectTargetingOptions,
  selectEventNameLabelsByName,
  (targeting, eventLabels) =>
    (targeting?.eventNames ?? [])
      .map(({ name }) => name)
      .filter(eventName => {
        const label = eventLabels?.[eventName];
        return label ? label?.showInUi : true;
      })
      .map(eventName => ({
        label: eventName.replace('appcues_custom:', ''),
        value: eventName,
      }))
);

export const selectFlowTargetingOptions = createSelector(
  selectTargetingOptions,
  targeting =>
    (targeting?.flows ?? [])
      .sort((a, b) => (a.name || a.id).localeCompare(b.name || b.id))
      .flatMap(({ name, id, steps = {} }) =>
        Object.values(steps)
          .filter(
            ({ stepType }) =>
              !['redirect', 'wait-for-page', 'action'].includes(stepType)
          )
          .sort((a, b) => a.index - b.index)
          .map(({ id: value, index, stepType }) => ({
            label: `${name || id} (Step ${index + 1})`,
            value,
            stepType,
          }))
      )
);

export const selectLaunchpadTargetingOptions = createSelector(
  selectTargetingOptions,
  targeting =>
    (targeting?.launchpads ?? [])
      .map(({ name, id }) => ({
        label: name,
        value: id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
);

export const selectMobileFlowTargetingOptions = createSelector(
  selectTargetingOptions,
  targeting =>
    (targeting?.mobile ?? [])
      .sort((a, b) => (a.name || a.id).localeCompare(b.name || b.id))
      .map(({ name, id }) => ({
        label: name ?? id,
        value: id,
      }))
);

export const selectSegmentTargetingOptions = createSelector(
  selectTargetingOptions,
  targeting =>
    (targeting?.segments ?? [])
      .map(({ name, id }) => ({
        label: name || id,
        value: id,
      }))
      .sort((a, b) => {
        const aName = a.label && a.label.toLowerCase();
        const bName = a.label && b.label.toLowerCase();
        return aName.localeCompare(bName, undefined, { numeric: true });
      })
);

/**
 * Creates dropdown options from `selectTags` results
 *
 * @param {State} state - Redux state
 * @return {Array.<{ label: string, id: string }>}
 */
export const selectTagsOptions = createSelector(selectTags, tags =>
  Object.values(tags ?? {})
    .map(({ id, name }) => ({
      label: name,
      value: id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
);

/**
 * Creates dropdown options from `selectThemes` results
 *
 * @param {State} state - Redux state
 * @return {Array.<{ label: string, id: string }>}
 */
export const selectThemesOptions = createSelector(selectThemes, themes =>
  Object.values(themes ?? {})
    .map(({ id, name = '' }) => ({
      label: name,
      value: id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
);

const ATTRIBUTE_DENYLIST = new Set(['_ABGroup', '_testContentId']);

/**
 * Merges profile attributes & labels to return dropdown options
 *
 * @param {State} state - Redux state
 * @return {Object} Dropdown options formatted for react-select
 */

export const selectProfileAttributeOptions = createSelector(
  [selectTargetingOptions, selectProfileAttributeLabels],
  (targeting, labels) =>
    Object.values(
      (targeting?.profileAttributes ?? [])
        .map(({ name }) => name)
        .filter(name => !ATTRIBUTE_DENYLIST.has(name))
        .filter(name => {
          const { showInUI = true } = labels?.[name] || {};
          return Boolean(showInUI);
        })
        .map(name => {
          const { label } = labels?.[name] || {};

          return {
            label: label || getPropertyDisplayName(name),
            value: name,
          };
        })
        .reduce(
          (acc, option) => {
            const { value } = option;

            if (/^(_deviceType|_operatingSystem|_browser)/.test(value)) {
              acc.device.options.push(option);
            } else if (value.startsWith('_appcuesForm_')) {
              acc.device.options.push(option);
            } else if (value.startsWith('_appcuesSatisfaction_')) {
              acc.satisfaction.options.push(option);
            } else if (value.startsWith('_')) {
              acc.auto.options.push(option);
            } else {
              acc.custom.options.push(option);
            }

            return acc;
          },
          {
            custom: {
              label: 'CUSTOM PROPERTIES',
              options: [],
              order: 1,
            },
            form: {
              label: 'FORM RESPONSES',
              options: [],
              order: 2,
            },
            device: {
              label: 'DEVICE PROPERTIES',
              options: [],
              order: 3,
            },
            satisfaction: {
              label: 'USER SATISFACTION',
              options: [],
              order: 4,
            },
            auto: {
              label: 'AUTO-PROPERTIES',
              options: [],
              order: 5,
            },
          }
        )
    )
);

export const selectGroupAttributeOptions = createSelector(
  selectTargetingOptions,
  targeting =>
    (targeting?.groupAttributes ?? [])

      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ name }) => ({
        label: name,
        value: name,
      }))
);

/**
 * Select list of flow template for the flow template dropdown
 *
 * @returns {Object} template
 */
export const selectTemplateOptions = () => {
  return Object.values(FLOW_TEMPLATE).map(
    ({ label, description, value, screenshotUrl, size }) => {
      return { label, description, value, screenshotUrl, size };
    }
  );
};

/**
 * Select apps to return dropdown options
 *
 * @param {State} state - Redux state
 * @return {Object} Dropdown options formatted for react-select
 */
export const selectAppOptions = createSelector(selectApps, apps =>
  Object.values(apps ?? {})
    .filter(({ state }) => state === 'enabled')
    .map(({ id: appId, name: appName, platform }) => ({
      label: appName,
      value: appId,
      platform,
      icon: MOBILE_PLATFORM_ICONS[platform],
    }))
);

export const selectGoalOptions = createSelector(selectGoals, goals =>
  Object.keys(goals ?? {})
    .map(goalId => ({
      label: goals[goalId].name || goalId,
      value: goalId,
      disabled: goals[goalId].disabled,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
);

/**
 * Select apps to return dropdown options and add web app
 *
 * @param {State} state - Redux state
 * @return {Object} Dropdown options formatted for react-select
 */
export const selectAppOptionsWithWeb = createSelector(
  selectAppOptions,
  appOptions => [
    ...appOptions,
    { label: 'Web app', value: WEB_APP, icon: ['fas', 'desktop'] },
  ]
);

export const selectFlowsOptions = createSelector(
  selectTargetingOptions,
  targeting =>
    (targeting?.flows ?? [])
      .map(({ id, name }) => ({ label: name || id, value: id }))
      .sort((a, b) => a.label.localeCompare(b.label))
);

export const selectMobileFlowsOptions = createSelector(
  selectTargetingOptions,
  targeting => {
    return (targeting?.mobile ?? [])
      .map(({ id, name, type, app }) => ({
        label: name || id,
        value: id,
        type,
        app,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }
);
