import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
  PanelHeader,
  PanelTitle,
  H3,
  ExpandableText,
  Text,
  TextClickToCopy,
  Spinner,
  Icon,
  Tooltip,
  ExternalLink,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
} from '@studio/legacy-components';
import { format } from 'next/lib/date';
import { OpenInBuilderManager } from 'next/components/OpenInBuilder';
import { openExperience } from 'next/lib/builder';
import { readAnalytics } from 'next/entities/analytics';
import { selectPineIssues } from 'next/lib/selectors';
import { queryExperienceIssues } from 'next/client/queries';
import useToggle from 'next/hooks/use-toggle';
import NoDataImg from 'next/assets/images/no-data.svg';
import {
  LoaderWrapper,
  SubTitle,
  Help,
  NoResultsWrapper,
  TableWrapper,
  Image,
  IssuePanel,
  OpenBuilder,
  NameWrapper,
  CopyWrapper,
  EmptyValue,
  CopyButton,
} from './styled';

const ISSUES_OVERVIEW_DOC_URL =
  'https://docs.appcues.com/article/694-issues-reporting';

export const Issues = ({ id, previewUrl, analytics, onLoad }) => {
  const [sortColumn, setColumn] = useState('timestamp');
  const [sortDirection, setDirection] = useState('desc');
  const [visible, toggleVisible] = useToggle(false);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const analyticsWithSort = useMemo(() => {
    if (!analytics) {
      return null;
    }

    const sorted = analytics?.sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (sortDirection === 'asc') {
        return aValue > bValue ? 1 : -1;
      }

      return aValue < bValue ? 1 : -1;
    });

    return sorted;
  }, [analytics, sortColumn, sortDirection]);

  const handleSortChange = column => {
    if (column === sortColumn) {
      setDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setColumn(column);
      setDirection('desc');
    }
  };

  const handleNavigate = () =>
    openExperience({ experience: id, url: previewUrl });

  const hasNoResults = analyticsWithSort?.length === 0;

  return (
    <IssuePanel>
      <PanelHeader>
        <PanelTitle>
          <H3>Issues reporting</H3>
          <SubTitle>
            <Text>Showing the 25 most recent issues</Text>
            <Tooltip
              placement="top"
              label={
                <Help>
                  Issues occur when Appcues cannot display a step due to a
                  missing or incorrect selector.
                  <ExternalLink href={ISSUES_OVERVIEW_DOC_URL}>
                    Learn more
                  </ExternalLink>
                </Help>
              }
              size="m"
              wrapped
              persist
            >
              <Icon icon="info-circle" />
            </Tooltip>
          </SubTitle>
        </PanelTitle>
      </PanelHeader>

      <TableWrapper>
        <Table>
          <Thead>
            <Tr>
              <Th
                title="Step"
                sortable
                sorted={sortColumn === 'step_id' ? sortDirection : null}
                onClick={() => handleSortChange('step_id')}
              >
                Pin name
              </Th>
              <Th
                title="User ID"
                sortable
                sorted={sortColumn === 'user_id' ? sortDirection : null}
                onClick={() => handleSortChange('user_id')}
              >
                User ID
              </Th>
              <Th
                title="URL"
                sortable
                sorted={sortColumn === 'step_error_url' ? sortDirection : null}
                onClick={() => handleSortChange('step_error_url')}
              >
                URL
              </Th>
              <Th
                title="Issue"
                sortable
                sorted={
                  sortColumn === 'step_error_message' ? sortDirection : null
                }
                onClick={() => handleSortChange('step_error_message')}
              >
                Issue
              </Th>
              <Th
                title="Event time"
                sortable
                sorted={sortColumn === 'timestamp' ? sortDirection : null}
                onClick={() => handleSortChange('timestamp')}
              >
                Event time
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!analytics && (
              <Tr>
                <Td colSpan={5}>
                  <LoaderWrapper>
                    <Spinner />
                  </LoaderWrapper>
                </Td>
              </Tr>
            )}
            {hasNoResults && (
              <Tr>
                <Td colSpan={5}>
                  <NoResultsWrapper>
                    <Image src={NoDataImg} alt="No data found" />
                    No results, please try different filters.
                  </NoResultsWrapper>
                </Td>
              </Tr>
            )}
            {analyticsWithSort?.map(
              (
                {
                  user_id: user,
                  name,
                  step_error_url: url,
                  step_error_message: issue,
                  timestamp,
                },
                i
              ) => (
                // eslint-disable-next-line react/no-array-index-key
                <Tr key={user + timestamp + i}>
                  <Td>
                    <NameWrapper>
                      {name}
                      <OpenBuilder
                        title="Open in Builder"
                        onClick={toggleVisible}
                        icon="external-link-alt"
                      />
                    </NameWrapper>
                  </Td>
                  <Td>
                    <Link to={`/users/${encodeURIComponent(user)}/`}>
                      {user}
                    </Link>
                  </Td>
                  <Td>
                    {url ? (
                      <CopyWrapper>
                        <ExpandableText lines={2}>{url}</ExpandableText>
                        <CopyButton>
                          <TextClickToCopy
                            text={url}
                            title="Copy URL to clipboard"
                            icon
                            iconOnly
                          />
                        </CopyButton>
                      </CopyWrapper>
                    ) : (
                      <EmptyValue>-</EmptyValue>
                    )}
                  </Td>
                  <Td>
                    {issue ? (
                      <CopyWrapper>
                        <ExpandableText lines={2}>{issue}</ExpandableText>
                        <CopyButton>
                          <TextClickToCopy
                            text={issue}
                            title="Copy issue to clipboard"
                            icon
                            iconOnly
                          />
                        </CopyButton>
                      </CopyWrapper>
                    ) : (
                      <EmptyValue>-</EmptyValue>
                    )}
                  </Td>
                  <Td>{format(timestamp, 'MMM D, YYYY h:mmA')}</Td>
                </Tr>
              )
            )}

            <OpenInBuilderManager
              type="pins"
              visible={visible}
              onClose={toggleVisible}
              onNavigate={handleNavigate}
            />
          </Tbody>
        </Table>
      </TableWrapper>
    </IssuePanel>
  );
};

Issues.propTypes = {
  id: PropTypes.string.isRequired,
  previewUrl: PropTypes.string.isRequired,
  analytics: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.string,
      name: PropTypes.string,
      step_error_url: PropTypes.string,
      step_error_message: PropTypes.string,
      timestamp: PropTypes.string,
    })
  ),
  onLoad: PropTypes.func,
};

const getQueryParams = ({ id, startTime, endTime }) => ({
  experienceId: id,
  startTime,
  endTime,
});

const mapStateToProps = (state, ownProps) => {
  const queryParams = getQueryParams(ownProps);

  return {
    analytics: selectPineIssues(state, queryParams, ownProps?.id),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const queryParams = getQueryParams(ownProps);
  const query = queryExperienceIssues(queryParams);

  return {
    onLoad: () => dispatch(readAnalytics(query)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Issues);
