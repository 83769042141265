/**
 * NOTE: Below are the "registered" feature flags that can be used within the
 *       application. Ideally, we should filter out any other features returned
 *       by LaunchDarkly and other gating services so that we only utilize the
 *       ones that are known. This will also help us with future maintainence of
 *       feature flags within those respective services so we can clean them up
 *       after they have been fully released.
 */

/**
 * Beta flags
 */
export const CHECKLIST_TARGETING_BETA = 'CHECKLIST_TARGETING_BETA';
export const SINGLE_SIGN_ON_BETA = 'SINGLE_SIGN_ON_BETA';
export const GROUP_PROPERTY_TARGETING = 'GROUP_PROPERTY_TARGETING';
export const RESET_BETA = 'RESET_BETA';
export const RESET = 'RESET';
export const PIN_MOBILE_CONDITIONS = 'PIN_MOBILE_CONDITIONS';
export const NEXT_FLOW_SETTINGS = 'NEXT_FLOW_SETTINGS';
export const NEXT_SUBSCRIPTION = 'NEXT_SUBSCRIPTION';
export const NEXT_USERS_PAGE = 'NEXT_USERS_PAGE';
export const S5_USER_PROFILE = 'S5_USER_PROFILE';
export const S5_PROPERTIES = 'S5_PROPERTIES';
/**
 * Feature flags
 */
export const CANNY = 'CANNY';
export const CHECKLIST_TARGETING = 'CHECKLIST_TARGETING';
export const EVENT_TRIGGERING = 'EVENT_TRIGGERING';
export const MOBILE_FLOWS = 'MOBILE_FLOWS';
export const MOBILE_PUBLISHING = 'MOBILE_PUBLISHING';
export const PASSWORDLESS_INVITATIONS = 'PASSWORDLESS_INVITATIONS';
export const SEGMENT_EXPORT = 'SEGMENT_EXPORT';
export const SINGLE_SIGN_ON = 'SINGLE_SIGN_ON';
export const EVENT_ATTRIBUTE_TRIGGERING = 'EVENT_ATTRIBUTE_TRIGGERING';
export const FLOW_FREQUENCY = 'FLOW_FREQUENCY';
export const PIN_ANALYTICS = 'PIN_ANALYTICS';
export const PIN_ANALYTICS_SEGMENT_FILTER = 'PIN_ANALYTICS_SEGMENT_FILTER';
export const PIN_ISSUES = 'PIN_ISSUES';
export const MOBILE_ANALYTICS = 'MOBILE_ANALYTICS';
export const MOBILE_ANALYTICS_SEGMENT_FILTER =
  'MOBILE_ANALYTICS_SEGMENT_FILTER';
export const WEB_FLOWS_ANALYTICS_SEGMENT_FILTER =
  'WEB_FLOWS_ANALYTICS_SEGMENT_FILTER';
export const FLOW_PRIORITIZATION = 'FLOW_PRIORITIZATION';
export const FLOW_PERFORMANCE = 'FLOW_PERFORMANCE';
export const ENTITLEMENTS = 'ENTITLEMENTS';
export const TRIAL_BY_DAYS = 'TRIAL_BY_DAYS';
export const MOBILE_SURVEY_EXPORT = 'MOBILE_SURVEY_EXPORT';
export const RUDDERSTACK_INTEGRATION = 'RUDDERSTACK_INTEGRATION';
export const WELCOME_LOGOUT_BUTTON = 'WELCOME_LOGOUT_BUTTON';
export const HIDE_FLYWHEEL_FROM_TRIALS = 'HIDE_FLYWHEEL_FROM_TRIALS';
export const TYPEFORM_EMBED = 'TYPEFORM_EMBED';
export const WEBHOOK_BASIC = 'WEBHOOK_BASIC';
export const BRAZE_INTEGRATION = 'BRAZE_INTEGRATION';
export const FULLSTORY_INTEGRATION = 'FULLSTORY_INTEGRATION';
export const HOTJAR_INTEGRATION = 'HOTJAR_INTEGRATION';
export const LOGROCKET_INTEGRATION = 'LOGROCKET_INTEGRATION';
export const S5_SEGMENTS_LIST = 'S5_SEGMENTS_LIST';
export const OPEN_BUILDER_FLOWS = 'OPEN_BUILDER_FLOWS';
export const SURVEYS_RESPONSE = 'SURVEYS_RESPONSE';
export const HIDE_FLYWHEEL = 'HIDE_FLYWHEEL';
export const BANNERS_CONDITIONS = 'BANNERS_CONDITIONS';
export const SEGMENT_USER_ID_UPLOAD = 'SEGMENT_USER_ID_UPLOAD';
export const INTEGRATION_LISTING_PAGE_REDESIGN =
  'INTEGRATION_LISTING_PAGE_REDESIGN';
export const LAUNCHPADS_V2 = 'LAUNCHPADS_V2';
export const LAUNCHPADS_V2_ANALYTICS = 'LAUNCHPADS_V2_ANALYTICS';
export const SUBSCRIPTION_MOBILE_MAUS = 'SUBSCRIPTION_MOBILE_MAUS';
export const SUBSCRIPTION_COMBINED_MAUS = 'SUBSCRIPTION_COMBINED_MAUS';
export const GOALS_EVENTS_EXPLORER = 'GOALS_EVENTS_EXPLORER';
export const IMAGE_SERVICE_LAMBDA = 'IMAGE_SERVICE_LAMBDA';
export const OVERVIEW_ACTIVITY = 'OVERVIEW_ACTIVITY';
export const FLOW_LOCALIZATION = 'FLOW_LOCALIZATION';
export const PAGE_COLLECTIONS = 'PAGE_COLLECTIONS';
export const AB_TESTING = 'AB_TESTING';
export const OPM_NEW_NAV = 'OPM_NEW_NAV';
export const WORKFLOWS = 'WORKFLOWS';

/**
 * System flags
 */
export const DISABLE_FULLSTORY = 'DISABLE_FULLSTORY';

/**
 * Date range flags
 *
 * NOTE: Despite their names, these flags are not NPS specific. Ideally we
 *       should update these flags to better repreesnt and handle the different
 *       date range options available based on tier...
 */
export const NPS_RANGE_SM = 'NPS_RANGE_SM';
export const NPS_RANGE_MED = 'NPS_RANGE_MED';
export const NPS_RANGE_LG = 'NPS_RANGE_LG';

/**
 * Mobile platform flags
 */

export const REACT_NATIVE_PLATFORM = 'REACT_NATIVE_PLATFORM';
export const FLUTTER_PLATFORM = 'FLUTTER_PLATFORM';
export const XAMARIN_PLATFORM = 'XAMARIN_PLATFORM';
export const IONIC_PLATFORM = 'IONIC_PLATFORM';

/*
 * DevTools flag registy
 *
 * Registered feature flags that can be overridden in the DevTools along with
 * their default/fallback values
 */
export const DEVTOOLS_REGISTRY = {
  [AB_TESTING]: false,
  [CANNY]: false,
  [CHECKLIST_TARGETING]: false,
  [CHECKLIST_TARGETING_BETA]: false,
  [ENTITLEMENTS]: false,
  [EVENT_TRIGGERING]: false,
  [FLOW_PRIORITIZATION]: false,
  [GROUP_PROPERTY_TARGETING]: false,
  [PIN_MOBILE_CONDITIONS]: false,
  [MOBILE_FLOWS]: false,
  [MOBILE_PUBLISHING]: true,
  [NEXT_FLOW_SETTINGS]: false,
  [NEXT_SUBSCRIPTION]: false,
  [NEXT_USERS_PAGE]: false,
  [NPS_RANGE_LG]: false,
  [NPS_RANGE_MED]: false,
  [NPS_RANGE_SM]: false,
  [PASSWORDLESS_INVITATIONS]: false,
  [SEGMENT_EXPORT]: false,
  [SINGLE_SIGN_ON]: false,
  [SINGLE_SIGN_ON_BETA]: false,
  [RESET_BETA]: false,
  [RESET]: false,
  [EVENT_ATTRIBUTE_TRIGGERING]: false,
  [FLOW_FREQUENCY]: false,
  [GOALS_EVENTS_EXPLORER]: false,
  [PIN_ANALYTICS]: false,
  [PIN_ANALYTICS_SEGMENT_FILTER]: false,
  [PIN_ISSUES]: false,
  [MOBILE_ANALYTICS]: false,
  [MOBILE_ANALYTICS_SEGMENT_FILTER]: false,
  [REACT_NATIVE_PLATFORM]: false,
  [FLUTTER_PLATFORM]: false,
  [XAMARIN_PLATFORM]: false,
  [IONIC_PLATFORM]: false,
  [TRIAL_BY_DAYS]: false,
  [MOBILE_SURVEY_EXPORT]: false,
  [RUDDERSTACK_INTEGRATION]: false,
  [WELCOME_LOGOUT_BUTTON]: false,
  [FLOW_PERFORMANCE]: false,
  [HIDE_FLYWHEEL_FROM_TRIALS]: true,
  [TYPEFORM_EMBED]: false,
  [BANNERS_CONDITIONS]: false,
  [WEBHOOK_BASIC]: false,
  [BRAZE_INTEGRATION]: false,
  [FULLSTORY_INTEGRATION]: false,
  [HOTJAR_INTEGRATION]: false,
  [LOGROCKET_INTEGRATION]: false,
  [SURVEYS_RESPONSE]: false,
  [S5_SEGMENTS_LIST]: false,
  [OPEN_BUILDER_FLOWS]: false,
  [HIDE_FLYWHEEL]: false,
  [SEGMENT_USER_ID_UPLOAD]: false,
  [INTEGRATION_LISTING_PAGE_REDESIGN]: false,
  [LAUNCHPADS_V2]: false,
  [LAUNCHPADS_V2_ANALYTICS]: false,
  [WEB_FLOWS_ANALYTICS_SEGMENT_FILTER]: false,
  [SUBSCRIPTION_MOBILE_MAUS]: false,
  [SUBSCRIPTION_COMBINED_MAUS]: false,
  [IMAGE_SERVICE_LAMBDA]: false,
  [OVERVIEW_ACTIVITY]: false,
  [FLOW_LOCALIZATION]: false,
  [PAGE_COLLECTIONS]: false,
  [OPM_NEW_NAV]: false,
  [S5_USER_PROFILE]: false,
  [S5_PROPERTIES]: false,
  [WORKFLOWS]: false,
};
