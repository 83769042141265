import React from 'react';
import { PageBody } from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import IndexPage from 'components/Common/IndexPage';
import ListProvider from './ListProvider';

export default function UserListPage() {
  useTitle('Users | Appcues');

  return (
    <IndexPage title="Users">
      <PageBody>
        <ListProvider />
      </PageBody>
    </IndexPage>
  );
}
